@import "basics";

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.preview {
  height: 50px;
  width: 50px;
  object-fit: cover;
  object-position: center;
}

.icon {
  font-size: 16px;
  margin-right: 10px;
}

.addAttractionButton {
  margin-left: auto;
  width: 100px;
  flex: 0 0 100px;
}
