@import "basics";

.root {
  border: 0;
  background: $buttonBackground;
  letter-spacing: 0.19;
  padding: 0 10px;
  color: $buttonColor;
  flex: 1;
  transition: 300ms all ease-in-out;

  &:hover {
    background: $buttonBackgroundHover;
  }

  &:active {
    transform: scale(0.95);
  }

  &:disabled {
    opacity: 0.4;
  }
}

// styles
.danger {
  background: $buttonStyleDangerBackground;
  border-color: $buttonStyleDangerBackground;
  color: $buttonStyleDangerText;

  &:hover {
    background: $buttonStyleDangerBackgroundHover;
    border-color: $buttonStyleDangerBorderHover;
    color: $buttonStyleDangerTextHover;
  }
}

.primary {
  background: $buttonStylePrimaryBackground;
  border-color: $buttonStylePrimaryBackground;
  color: $buttonStylePrimaryText;

  &:hover {
    background: $buttonStylePrimaryBackgroundHover;
    border-color: $buttonStylePrimaryBorderHover;
    color: $buttonStylePrimaryTextHover;
  }
}

.success {
  background: $buttonStyleSuccessBackground;
  border-color: $buttonStyleSuccessBackground;
  color: $buttonStyleSuccessText;

  &:hover {
    background: $buttonStyleSuccessBackgroundHover;
    border-color: $buttonStyleSuccessBorderHover;
    color: $buttonStyleSuccessTextHover;
  }
}

// sizes
.xlarge {
  font-size: 17px;
  height: 50px;
  margin: 20px 0;
  border-radius: 10px;
  width: 100%;
  flex: 1 0 50px;
}

.large {
  font-size: 17px;
  height: 50px;
  margin: 20px 0;
  border-radius: 10px;
}

.medium {
  font-size: 15px;
  height: 40px;
  margin: 10px 0;
  border-radius: 10px;
}

.small {
  font-size: 13px;
  height: 32px;
  margin: 10px 0;
  border-radius: 10px;
}
