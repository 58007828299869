
@mixin box-wrapper {
  display: flex;
  flex-direction: row;
}

@mixin box-list {
  margin-bottom:2rem;

  &:not(:last-child){
    margin-right:2rem;
  }
}

@mixin box {
  box-shadow: 0 10px 35px 0 #38476d13;
  border: 0;
  margin-bottom:2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #eff2f5;
  border-radius: .85rem;
}