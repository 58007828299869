@import 'basics';

.root{
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: row;
  height:100vh;
  width:100vw;
}

.wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  flex:1;
}

.wrapperInner{
  width:450px;
  display: flex;
  flex-direction: column;
  background: white;
  padding: 25px;
  border-radius: 25px;
  margin-top:40px;
}

.aside{
  width: 600px;
  height:100vh;
  background-position: 20% 50%;
  background-size: cover;
}

.logo{
  width:250px;
  margin-top:130px;
}

.footerLinks{
  margin-top:auto;
  margin-bottom:20px;

  a{
    margin: 0 10px;
    color:$lightGray5;
    font-weight: 600;
  }
}