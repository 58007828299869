.ck-content{
  font-size:14px;
}

.ck-content h1,
.ck-heading_heading1{
  font-size:24px !important;
  color:red !important;
}
.ck-content h2,
.ck-heading_heading2{
  font-size:20px !important;
  font-weight:500 !important;
}
.ck-content h3,
.ck-heading_heading3{
  font-size:16px !important;
  font-weight:500 !important;
}