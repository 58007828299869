@import 'basics';

.root{
  flex:1;
  width:100%;
  margin:15px 0;
}

.list{
  display: flex;
  flex-direction: row;
  margin-bottom:6px;
}

.option{
  height:80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  border: 1px dashed #e4e6ef;
  border-radius: 6px;
  margin-right:1rem;
  background:transparent;
  transition: all 300ms ease-in-out;
  text-transform: capitalize; 

  &:hover,
  &.active{
    border: 1px dashed $grapePurple;
    background:$paleOrange1;
    color:$grapePurple
  }

  img{
    height:30px;
    width:30px;
    object-fit: contain;
    object-position: center;
    pointer-events: none;
  }
}

.error{
  text-align: left;
  color:$inputErrorColor;
}