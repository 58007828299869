@import 'basics';

.sidebar{
  width:$sidebar-width;
  position: fixed;
  left:0;
  top:0;
  bottom:0;
  background:$sidebarBackground;
  border-right:1px solid $sidebarBackgroundBorder;
  display: flex;
  flex-direction: column;
}

.header{
  background: $sidebarBackgroundHeader;
  height:$header-height;
  width:100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo{
  height: 40px;
}

.menu{
  display: flex;
  flex-direction: column;
  flex:1;
  padding:25px 0;
  align-items: center;
}

.link{
  height:43px;
  width:43px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background:transparent;
  transition: 300ms ease-in-out background-color;
  border-radius: 6px;
  margin-bottom:7px;
  position: relative;

  &::after{
    display: block;
    content: attr(data-title);
    position: absolute;
    left:50px;
    background:$sidebarBackground;
    border:1px solid $sidebarBackgroundBorder;
    padding: 5px 10px;
    border-radius: 6px;
    pointer-events: none;
    color:$sidebarLinkColor;
    opacity: 0;
    transition: 300ms ease-in-out opacity;
  }
  
  svg{
    font-size:23px;
  }
  
  svg *{
    transition: 300ms ease-in-out fill;
    fill:$sidebarLinkColor;
  }

  &:hover, &.active{
    background:$sidebarLinkBackground;

    svg * {
      fill:$sidebarLinkColorActive;
    }
  }

  &:hover::after{
    opacity: 1;
  }
}