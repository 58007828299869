@import 'basics';

.root{
  flex:1;
  width:100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin:15px 0;
}

.option{
  height:40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed #e4e6ef;
  border-radius: 6px;
  margin-right:1rem;
  margin-bottom:1rem;
  flex: 0 0 60px;
  font-size:12px;
  background:transparent;
  transition: all 300ms ease-in-out;
  text-transform: capitalize; 

  &:hover,
  &.active{
    border: 1px dashed $grapePurple;
    background:$paleOrange1;
    color:$grapePurple
  }
}

.error{
  text-align: left;
  margin-top:4px;
  color:$inputErrorColor;
}