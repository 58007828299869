@import "basics";

.root {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 15px 0;
}

.input {
  border: none;
  height: 55px;
  font-size: 17px;
  background: $inputEditorBackground;
  border-radius: 20px;
  padding: 0 15px;

  &::placeholder {
    color: $inputEditorPlaceholder;
  }
}

.id {
  text-align: left;
  margin-top: 4px;
  font-size: 10px;
}

.error {
  text-align: left;
  margin-top: 4px;
  color: $inputErrorColor;
}
