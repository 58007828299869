@import 'basics';
@import 'reset';
@import './content';
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;300;400;500;600;700;900&display=swap');

html{
  font-family: $font-family;
  background:$pageBackground;
}

body {
  margin: 0;
  padding:0;
  font-family: $font-family;
  line-height: 1.5;
  font-size: 1rem;
  background:$pageBackground;
  color:$fontColor;
}

input, button, textarea{
  font-family: $font-family;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

* {
  box-sizing: border-box;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

h1 {
  font-size:1.5rem;
  font-weight:900;
  line-height:2.09;
}

h2 {
  font-size:1.35rem;
  font-weight:900;
}

h3 {
  font-size:1.25rem;
  font-weight:700;
}

h4 {
  font-size:14px;
  font-weight:700;
}

a{
  text-decoration: none;
  color:$linkColor;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}