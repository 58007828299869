@import "basics";

.mapIcon {
  font-size: 2rem;
}

.locationNote {
  font-size: 12px;
}

.mapStyles {
  margin-left: auto;
}

.mapStylesButton {
  margin-left: 5px;
}

.drawButton {
  margin-right: 5px;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
