@import 'basics';

.root{
  display: flex;
  background: $screenBackground;
  min-height:100vh;
  width:100vw;
  padding-left:$sidebar-width;
}

.page{
  padding:$page-padding;
  flex:1
}