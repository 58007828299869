@import 'basics';


.header{
  color:$lightDark;
  font-weight: 600;
  font-size:26px;
}

.content{
  font-size:18px;
  color:$veryLightGray;
  margin-bottom:20px;
}

.inputWrapper{
  margin-top:30px
}

.google{
  height:22px;
  margin-right:10px;
  vertical-align: middle;
  margin-top: -3px;
}

.error{
  color:$peachyPink;
}