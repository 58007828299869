// Colors
$black: #000000;
$darkBlack: #181c32;
$white: #ffffff;
$lightBackground: #f3f6f9;
$lightDark: #181c32;
$transparent: transparent;
$lightSkyBlue: #3ba5f4;
$peachyPink: #ff8080;
$veryLightGray: #b5b5c3;
$veryLightGray2: #f3f4f5;
$veryLightGray3: #e3e3e3;
$darkGrey: #aaaaaa;
$darkGray1: #7e8299;
$darkSlateBlue: #193f6a;
$LighterGray: #fafafa;
$lightGray: #f8f8f8;
$lightGray2: #f5f5f5;
$lightGray3: #8c90a5;
$lightGray4: #e4e6ef;
$lightGray5: #a1a5b7;
$lightGray3-40: rgba($lightGray3, 0.4);
$lightDarkGray: #f0f0f0;
$greenishCyan: #34f5cf;
$greenishCyanTwo: #31f4cf;
$brownGrey: #989898;
$lightSeaGreen: #2ecc71;
$lightOrange: #eff2f5;
$paleOrange1: #faf0e5;
$paleOrange: #ffbf9a;
$grapePurple: #78232f;
$grape: #67131f;

// Theme
$pageBackground: $white;
$fontColor: $darkBlack;
// Input
$inputBorderColor: $veryLightGray;
$inputPlaceholder: $veryLightGray;
$inputEditorBackground: $lightGray2;
$inputEditorPlaceholder: $veryLightGray;
$inputErrorColor: $peachyPink;

// Button
$buttonBackground: $veryLightGray2;
$buttonBackgroundHover: $lightGray4;
$buttonColor: $lightGray3;

$screenBackground: $lightBackground;
$sidebarBackground: $white;
$sidebarBackgroundBorder: $lightOrange;
$sidebarBackgroundHeader: $grape;

$sidebarLinkBackground: $lightBackground;
$sidebarLinkColor: $darkGray1;
$sidebarLinkColorActive: $grapePurple;

$sidebarBorderColorItem: $lightGray3-40;

$drawerTextColor: $black;
$drawerVersion: $darkGrey;
$tabBarBackground: $LighterGray;
$tabBarBackgroundActive: $lightGray2;
$tabBarIconDefaultColor: $darkSlateBlue;
$tabBarIconDefaultColorActive: $lightSkyBlue;
$headerBackground: $darkSlateBlue;
$headerTextColor: $white;
$headerIcon: $white;
$modalBackground: $white;
$modalHeaderTitle: $lightSkyBlue;
$contentExpiryDateGood: $lightSeaGreen;
$contentExpired: $peachyPink;
$backButton: $black;
$onboardingBackground: $darkSlateBlue;
$onboardingController: $white;
$onboardingHeader: $greenishCyanTwo;
$onboardingContent: $white;
$searchInput: $veryLightGray3;
$filterByTypeText: $brownGrey;
$filterByTypeTextActive: $darkSlateBlue;
$searchBackButton: $darkSlateBlue;
$searchText: $darkSlateBlue;

// Content
$contentItemBackground: $white;
$contentItemTitle: $darkSlateBlue;
$imagePlaceholder: $veryLightGray3;
$boxContentTextColor: $black;
$addSectionButtonBackground: $darkSlateBlue;

// Knowledge item
$knowledgeItemBackground: $lightGray2;
$knowledgeItemBackgroundLight: $white;
$knowledgeItemTitle: $black;
$knowledgeItemSubtitle: $brownGrey;
$knowledgeItemIconWrapper: $lightGray2;
$knowledgeItemIconWrapperWeb: $white;
$knowledgeItemIconWrapperWebHover: $veryLightGray3;

// Notification Prompt
$notificationPromptBackground: $darkSlateBlue;
$notificationPromptTextColor: $white;
$notificationPromptConfirmButtonFill: $lightSkyBlue;
$notificationPromptConfirmButtonText: $white;

// Updated Indication
$updatedIndicationColor: $greenishCyan;

// Search
$searchEmptyScreenHeader: $darkSlateBlue;

// Header
$dropdownBorder: $lightGray3-40;

// Button styles
$buttonStyleDangerBackground: $peachyPink;
$buttonStyleDangerBackgroundHover: $transparent;
$buttonStyleDangerBorderHover: $black;
$buttonStyleDangerText: $black;
$buttonStyleDangerTextHover: $black;

$buttonStylePrimaryBackground: $lightSkyBlue;
$buttonStylePrimaryBackgroundHover: $darkSlateBlue;
$buttonStylePrimaryBorderHover: $darkSlateBlue;
$buttonStylePrimaryText: $white;
$buttonStylePrimaryTextHover: $white;

$buttonStyleSuccessBackground: $lightSeaGreen;
$buttonStyleSuccessBackgroundHover: rgba($lightSeaGreen, 0.8);
$buttonStyleSuccessBorderHover: $lightSeaGreen;
$buttonStyleSuccessText: $white;
$buttonStyleSuccessTextHover: $white;

$font-family: "Heebo", sans-serif;
$linkColor: $lightSkyBlue;
$page-padding: 15px 40px;

$sidebar-width: 76px;
$header-height: 65px;
$box-padding: 15px;
