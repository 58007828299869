@import "basics";

.header {
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
}

.wrapper {
  @include box-wrapper;
}

.editor {
  flex: 3;
  @include box-list;
}

.aside {
  flex: 1 0 250px;
  max-width: 350px;
  @include box-list;
  position: relative;

  .actions {
    position: sticky;
    top: 10px;
  }
}

.box {
  @include box;
  padding: $box-padding;
}

.actionsButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.actionButton {
  flex: 0 0 100px;
  margin-right: 10px;
}

.state {
  max-width: 250px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px 0px;
}
