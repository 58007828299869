@import 'basics';

.root{
  flex:1;
  width:100%;
  display: flex;
  flex-direction: column;
  margin:15px 0;
}

.error{
  text-align: left;
  margin-top:4px;
  color:$inputErrorColor;
}